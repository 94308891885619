/** @jsx jsx */
import { jsx, Button, Flex, Grid, Box, Container, Styled } from 'theme-ui'
import React, { useContext, useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'

import { FirebaseContext } from "../context"

import { SignUp } from '../components/Auth'
import BackgroundImage from 'gatsby-background-image'
import SEO from "../components/seo"
import BreedImages from "../components/BreedImages"





const Breed = ({data}) => {

  const { user, firebase } = useContext(FirebaseContext)
  const [modal, setModal] = useState(false)
  const [following, setFollowing] = useState(false)

  const [position, setPosition] = useState(0)
  const [ scroll, setScroll] = useState(false)

  const breed = data.breed
  const posts = data.posts.edges
  const allbreedposts = data.allBreedPosts.edges
  const images = data.breedImages.edges
  const breeders = data.breeders.edges


  const authCheck = () => {
    if (user) {
      firebase.followBreed(user, breed).then(() => {setFollowing(true)})
    } else {
      setModal(true)
    }
  }
  
  const toggleModal = () => {
    setModal(false)
  }

  useEffect(() => {
    if (user) {
      setModal(false)
      firebase.getFollowing(user, breed)
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                if (doc.data().breedID == breed.id) {
                  setFollowing(true)
                }
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    }
  }, [user])




  useEffect(() => {
    window.addEventListener("scroll", onScroll)
  },[])

  const onScroll = () => {
    const newPosition = window.scrollY
    if (newPosition > position) {
      setScroll(true)
    } else {
      setScroll(false)
    }

    setPosition(window.scrollY)

  }

 
  const emoji = (word) => {
    switch(word) {
      case "Friendly":
        return(
          <span role="img" aria-label="friendly">😀</span>
        )
      case "Protective":
        return(
          <span role="img" aria-label="protective">🤨</span>
        )
      case "Unriendly":
        return(
          <span role="img" aria-label="unfriendly">😠</span>
        )
      case "House":
        return(
          <span role="img" aria-label="house">🏠</span>
        )
      case "Apartment":
        return(
          <span role="img" aria-label="apartment">🏢</span>
        )
      case "Park":
        return(
          <span role="img" aria-label="park">🌳</span>
        )
      case "Walks":
        return(
          <span role="img" aria-label="walking dog">🦮</span>
        )
      case "Yard":
        return(
          <span role="img" aria-label="House with a yard">🏡</span>
        )
      case "Inside":
        return(
          <span role="img" aria-label="couch">🛋️</span>
        )
      case "Outside":
        return(
          <span role="img" aria-label="camping">🏕️</span>
        )
      default:
        // code block
    }
  } 


  const size = (word) => {
    switch(word) {
      case "XS":
        return(
          "extra-small"
        )
      case "S":
        return(
          "small"
        )
      case "M":
        return(
          "medium"
        )
      case "L":
        return(
          "large"
        )
      case "XL":
        return(
          "extra-large"
        )
      case "XXL":
        return(
          "gigantic"
        )
      default:
        // code block
    }
  } 


  return (
    <section>
      <SEO title={breed.title} />
      <Box sx={{width: '100%'}}>
        {images.length > 0 ?
          <BreedImages breed={images} />
        :
          null
        }
      </Box>

      {!following && (
        <Flex className={scroll ? 'show' : 'hide'} sx={{p: 4, position: "fixed", bottom: "0", left: "0", right: "0", width: "100%", zIndex: "3000",  display: ["flex", "flex","flex", "none"]}}>
          <Button onClick={authCheck} variant='rounded' sx={{width: '100%', boxShadow: 'medium'}}>
            <Flex sx={{alignItems: 'center', justifyContent: 'center'}}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style={{fill: "#fff"}}> 
                <path d="M16.5,3C13.605,3,12,5.09,12,5.09S10.395,3,7.5,3C4.462,3,2,5.462,2,8.5c0,4.171,4.912,8.213,6.281,9.49 C9.858,19.46,12,21.35,12,21.35s2.142-1.89,3.719-3.36C17.088,16.713,22,12.671,22,8.5C22,5.462,19.538,3,16.5,3z M14.811,16.11 c-0.177,0.16-0.331,0.299-0.456,0.416c-0.751,0.7-1.639,1.503-2.355,2.145c-0.716-0.642-1.605-1.446-2.355-2.145 c-0.126-0.117-0.28-0.257-0.456-0.416C7.769,14.827,4,11.419,4,8.5C4,6.57,5.57,5,7.5,5c1.827,0,2.886,1.275,2.914,1.308L12,8 l1.586-1.692C13.596,6.295,14.673,5,16.5,5C18.43,5,20,6.57,20,8.5C20,11.419,16.231,14.827,14.811,16.11z"></path>
              </svg> <span sx={{ml: 2}}>Save</span>
            </Flex>
          </Button>
        </Flex>
      )}

      <Container sx={{px: 4, mt: 5, maxWidth: 'max'}}>
        <Flex>
        <small><Styled.a as={Link} to={`/breeds`}>Breeds</Styled.a> <span>{`>`}</span> <Styled.a as={Link} to={`/breed-groups/${breed.breedGroups.slug.current}`}>{breed.breedGroups.title}</Styled.a> <span>{`>`}</span> <Styled.a as={Link} to={`/breeds/${breed.slug.current}`}>{breed.title}</Styled.a></small>
        </Flex>
        <Flex sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <Styled.h1 sx={{mb: '1', fontSize: ['32px','32px',6,6]}}>{breed.title}</Styled.h1>
            <div sx={{display: 'inline-block'}}>
              <Styled.h6 sx={{backgroundColor: '#f5f5f5', borderRadius: '99px', px: 5, py: 3}}>{breed.breedGroups.title}</Styled.h6>
            </div>
          </Box>
          {!following && (
            <Button onClick={authCheck} variant='rounded' sx={{display: ['none','none','block']}}>
              <Flex sx={{alignItems: 'center', justifyContent: 'center'}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style={{fill: "#fff"}}> 
                  <path d="M16.5,3C13.605,3,12,5.09,12,5.09S10.395,3,7.5,3C4.462,3,2,5.462,2,8.5c0,4.171,4.912,8.213,6.281,9.49 C9.858,19.46,12,21.35,12,21.35s2.142-1.89,3.719-3.36C17.088,16.713,22,12.671,22,8.5C22,5.462,19.538,3,16.5,3z M14.811,16.11 c-0.177,0.16-0.331,0.299-0.456,0.416c-0.751,0.7-1.639,1.503-2.355,2.145c-0.716-0.642-1.605-1.446-2.355-2.145 c-0.126-0.117-0.28-0.257-0.456-0.416C7.769,14.827,4,11.419,4,8.5C4,6.57,5.57,5,7.5,5c1.827,0,2.886,1.275,2.914,1.308L12,8 l1.586-1.692C13.596,6.295,14.673,5,16.5,5C18.43,5,20,6.57,20,8.5C20,11.419,16.231,14.827,14.811,16.11z"></path>
                </svg> <span sx={{ml: 2}}>Save</span>
              </Flex>
            </Button>
          )}

          {following && (
            <Button onClick={authCheck} variant='rounded' sx={{backgroundColor: '#f5f5f5', color: '#000', display: ['none','none','block']}}>
              <Flex sx={{alignItems: 'center', justifyContent: 'center'}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style={{fill: "#000"}}> 
                  <path d="M16.5,3C13.605,3,12,5.09,12,5.09S10.395,3,7.5,3C4.462,3,2,5.462,2,8.5c0,4.171,4.912,8.213,6.281,9.49 C9.858,19.46,12,21.35,12,21.35s2.142-1.89,3.719-3.36C17.088,16.713,22,12.671,22,8.5C22,5.462,19.538,3,16.5,3z"></path>
                </svg> <span sx={{ml: 2}}>Saved</span>
            </Flex>
            </Button>
          )}
        </Flex>
        <Grid columns={[1,1,'1.5fr 1fr']} gap={5} sx={{mt: 5}}>
          {/* Column 1 */}
          <Box sx={{order: [1,1,0]}}>
            <Box sx={{display: ['none','none','block'], mb: 5}}>
              {/* <BlockContent blocks={breed._rawIntroduction} serializers={defaultSerializers} projectId="9pk1j8nz" dataset="production"/> */}
              <Styled.p>The {breed.title} was originally from {breed.origin}. This breed typically lives {breed.breedVitals.lifeSpan} years and is generally considered a {size(breed.size)} size dog.
              They stand at {breed.breedVitals.height} inches from the shoulder and weigh between {breed.breedVitals.weight} pounds.</Styled.p>
              <Styled.p>{breed.title}'s need roughly {breed.breedCharacteristics.exerciseNeeds.toLowerCase()} minutes
              of exercise. Taking their age, size and exercise needs into account they will need about {breed.breedCharacteristics.foodQuantity} cups of food on average in a day.</Styled.p> 
              <Styled.p>Owners of the {breed.title} will generally describe this dog as {breed.breedCharacteristics.generalTemperament.toLowerCase()}. They are on the {breed.breedCharacteristics.trainability.toLowerCase()} end
              of the trainability spectrum and they bark {breed.breedCharacteristics.barks.toLowerCase()}.</Styled.p>
              <Styled.p>When deciding the dog breed for your situation where you live and where the dog will live and play matters. The {breed.title} likes to live {breed.breedCharacteristics.insideOutside.toLowerCase()}.
              If you plan on having your dog be an inside dog the smallest recommended living space is a {breed.breedCharacteristics.indoorNeeds.toLowerCase()} and it will need a {breed.breedCharacteristics.outdoorNeeds.toLowerCase()} to stay
              physically and mentally stimulated.</Styled.p>
            </Box>

            {/* Diseases */}
            <Box sx={{mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>💊 Common Conditions</Styled.h6>
              {breed.diseases.map((disease,i) => (
                <Box key={i} sx={{boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px', px: 5, py: 4, mt: 3}}>
                  <Styled.h5 sx={{my: '0'}}>{disease.name}</Styled.h5>
               
                </Box>
              ))}
            </Box>

{/* 
            <Box sx={{mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>🙋 Recent Questions</Styled.h6>
              <Flex sx={{border: "1px dashed #9e9e9e", alignItems: "center", justifyContent: "center", borderRadius: '5px', px: 5, py: 4, mt: 3}}>
                <Box sx={{textAlign: 'center'}}>
                  <Box>
                    <small>It doesn't look like there are any questions</small>
                  </Box>
                  <small><Styled.a>Ask one.</Styled.a></small>
                </Box>
              </Flex> 

              {topics.map(({node,i}) => (
                <Box key={i} sx={{boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px', px: 5, py: 4, mt: 3}}>
                  <Styled.h5 sx={{my: '0'}}>{node.name}</Styled.h5>
                  <small>Asked by {node.user_name}</small>
                  <br />
                  <a href={`${node.url}`} sx={{fontSize: '14px'}} target="_blank" rel="noreferrer">{`See ${node.comments_count} Responses`}</a>
                </Box>
              ))}
            </Box> */}


            {/* <Box sx={{mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>📝 Recent Posts</Styled.h6>
              {posts.map(({node,i}) => (
                <Flex key={i} sx={{alignItems: 'center', boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px', mt: 3}}>
                   <BackgroundImage
                    fluid={node.mainImage.asset.fluid}
                    sx={{width: '100%', minWidth: '150px', maxWidth: '150px', minHeight: '150px'}}
                    backgroundColor={`#000`}
                  />
                  <Box sx={{px: 5, py: 4}}>
                    <Styled.h5 sx={{my: '0'}}>{node.title}</Styled.h5>
                    <Styled.a as={Link} to={`/posts/${node.slug.current}`}>Read More</Styled.a>
                  </Box>
                </Flex>
              ))}
               {allbreedposts.map(({node,i}) => (
                <Flex key={i} sx={{alignItems: 'center', boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px', mt: 3}}>
                   <BackgroundImage
                    fluid={node.mainImage.asset.fluid}
                    sx={{width: '100%', minWidth: '150px', maxWidth: '150px', minHeight: '150px'}}
                    backgroundColor={`#000`}
                  />
                  <Box sx={{px: 5, py: 4}}>
                    <Styled.h5 sx={{my: '0'}}>{node.title}</Styled.h5>
                    <Styled.a as={Link} to={`/posts/${node.slug.current}`}>Read More</Styled.a>
                  </Box>
                </Flex>
              ))}
            </Box> */}


            {/* <Box sx={{my: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>🐕 Breeders</Styled.h6>
              {breeders.map(({node,i}) => (
                <Box key={i} sx={{boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px', px: 5, py: 4, mt: 3}}>
                  <Styled.h5 sx={{my: '0'}}>{node.title}</Styled.h5>
                  <Styled.a as={Link} to={`/breeders/${node.slug.current}`}>See Breeder</Styled.a>
                </Box>
              ))}
            </Box> */}

          </Box>
          {/* Column 2 */}
          <Box sx={{order: [0,0,1], mb: ['0','0',7]}}>

            <Box sx={{display: ['block','block','none',]}}>
              {/* <BlockContent blocks={breed._rawIntroduction} serializers={defaultSerializers} projectId="9pk1j8nz" dataset="production"/> */}
              <Styled.p>The {breed.title} was originally from {breed.origin}. This breed typically lives {breed.breedVitals.lifeSpan} years and is generally considered a {size(breed.size)} size dog.
              They stand at {breed.breedVitals.height} inches from the shoulder and weigh between {breed.breedVitals.weight.toLowerCase()} pounds.</Styled.p>
              <Styled.p>{breed.title}'s need roughly {breed.breedCharacteristics.exerciseNeeds.toLowerCase()} minutes
              of exercise. Taking their age, size and exercise needs into account they will need about {breed.breedCharacteristics.foodQuantity.toLowerCase()} cups of food on average in a day.</Styled.p> 
              <Styled.p>Owners of the {breed.title} will generally describe this dog as {breed.breedCharacteristics.generalTemperament.toLowerCase()}. They are on the {breed.breedCharacteristics.trainability.toLowerCase()} end
              of the trainability spectrum and they bark {breed.breedCharacteristics.barks.toLowerCase()}.</Styled.p>
              <Styled.p>When deciding the dog breed for your situation where you live and where the dog will live and play matters. The {breed.title} likes to live {breed.breedCharacteristics.insideOutside.toLowerCase()}.
              If you plan on having your dog be an inside dog the smallest recommended living space is a {breed.breedCharacteristics.indoorNeeds.toLowerCase()} and it will need a {breed.breedCharacteristics.outdoorNeeds.toLowerCase()} to stay
              physically and mentally stimulated.</Styled.p>
            </Box>

            {/* Vitals */}
            <Styled.h6 sx={{fontWeight: 'bold'}}>🔎 Overview</Styled.h6>
            <Box sx={{backgroundColor: '#f5f5f5', boxShadow: 'medium', borderRadius: '5px', p: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>❤️ Health</Styled.h6>
              <Flex sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 1, fontWeight: 'bold'}}>Lifespan</Styled.h6>
                  <Styled.h5 sx={{my: 3}}>{breed.breedVitals.lifeSpan}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>Years</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 1, fontWeight: 'bold'}}>Exercise Needs</Styled.h6>
                  <Styled.h5 sx={{my: 3}}>{breed.breedCharacteristics.exerciseNeeds}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>Min Per Day</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 1,fontWeight: 'bold'}}>Food Quantity</Styled.h6>
                  <Styled.h5 sx={{my: 3}}>{breed.breedCharacteristics.foodQuantity}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>Cups Per Day</span>
                </Box>
              </Flex>
            </Box>

            <Box sx={{backgroundColor: '#f5f5f5', boxShadow: 'medium', borderRadius: '5px', p: 5, mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>📏 Measurements</Styled.h6>
              <Flex sx={{justifyContent: 'space-between'}}>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Size</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{breed.size}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>Generally</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Height</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{breed.breedVitals.height}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>Inches</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Weight</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{breed.breedVitals.weight}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>Pounds</span>
                </Box>
              </Flex>
            </Box>

            {/* Breed Characteristics */}

            <Box sx={{backgroundColor: '#f5f5f5', boxShadow: 'medium', borderRadius: '5px', p: 5, mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>🏠 Spatial Needs</Styled.h6>
              <Flex sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Indoor Space</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{emoji(breed.breedCharacteristics.indoorNeeds)}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.indoorNeeds}</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Outdoor Space</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{emoji(breed.breedCharacteristics.outdoorNeeds)}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.outdoorNeeds}</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Prefers to be</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{emoji(breed.breedCharacteristics.insideOutside)}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.insideOutside}</span>
                </Box>
              </Flex>
            </Box>

            <Box sx={{backgroundColor: '#f5f5f5', boxShadow: 'medium', borderRadius: '5px', p: 5, mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>😊 Temperament</Styled.h6>
              <Flex sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 1,fontWeight: 'bold'}}>Dispositon</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>{emoji(breed.breedCharacteristics.generalTemperament)}</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.generalTemperament}</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Trainability</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>🐕‍🦺</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.trainability}</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Barks</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>🔉</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.barks}</span>
                </Box>
              </Flex>
            </Box>

            <Box sx={{backgroundColor: '#f5f5f5', boxShadow: 'medium', borderRadius: '5px', p: 5, mt: 5}}>
              <Styled.h6 sx={{fontWeight: 'bold'}}>🐕 Grooming</Styled.h6>
              <Flex sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Sheds</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>✂️</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.sheds}</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Odor</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>🧼</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.odor}</span>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h6 sx={{mb: 0, fontWeight: 'bold'}}>Hypoallergenic</Styled.h6>
                  <Styled.h5 sx={{my: 2}}>🤧</Styled.h5>
                  <span sx={{fontSize: ['10px','10px',0,0], color: '#616161', textTransform: 'uppercase', fontWeight: 'bold'}}>{breed.breedCharacteristics.hypoallergenic ? "Yes!" : "Nope!"}</span>
                </Box>
              </Flex>
            </Box>

          </Box>
        </Grid>
      </Container>  

      {modal && (
        <>
          <div sx={{zIndex: '3000', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', width: '100%', height: '100vh', opacity: '0.75', backgroundColor: '#e0e0e0'}}></div>
          <div sx={{zIndex: '3001', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', width: '100%', height: 'auto'}}>
            <Flex sx={{alignItems: 'center', justifyContent: 'center', height: '100vh', mx: 'auto'}}>
              <Box sx={{p: 5, backgroundColor: 'white', width: 'small',boxShadow: 'medium', borderRadius: 'medium' }}>
                <SignUp redirect={''}/>
                <Styled.h6 onClick={toggleModal} sx={{cursor: 'pointer', textAlign: 'center', mt: 3}}>No thanks. Close Modal.</Styled.h6>
              </Box>
            </Flex>
          </div>
        </>
      )}
    </section>
  )
}

export default Breed

export const query = graphql`
  query($slug: String!) {
    breed: sanityBreed(slug: {current: {eq: $slug}}) {
      id
      title
      slug {
        current
      }
      breedGroups {
        title
        slug {
          current
        }
      }
      yearRecognizedAKC
      origin
      size
      _rawIntroduction
      _rawHistory
      breedVitals {
        height
        weight
        lifeSpan
      }
      diseases {
        name
        _rawDescription
      }
      breedCharacteristics {
        indoorNeeds
        outdoorNeeds
        insideOutside
        exerciseNeeds
        foodQuantity
        generalTemperament
        trainability
        barks
        sheds
        odor
        hypoallergenic
      }
    }

    breedImages: allSanityBreedImage(filter: {breed: {slug: {current: {eq: $slug}}}}) {
      edges {
        node {
          image {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          altText
          source
        }
      }
    }

    posts: allSanityPost(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }

    allBreedPosts: allSanityPost(filter: {allBreeds: {eq: true}}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }

    breeders: allSanityBreeder(filter: {breeds: {elemMatch: {slug: {current: {eq: $slug}}}}}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }


  }
`